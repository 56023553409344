<template>
	<div class="commonHeader" :style="{backgroundImage: 'url(' + bg_src  + ')', backgroundSize: bg_size,paddingBottom: paddingB}">
		<div class="head">
			<div class="left" v-if="isReturn" @click="onClickLeft">
				<img src="../assets/imgs/new_color/tiao_left.png" />
			</div>
			<div class="title">
				<span v-if="isReturn">{{ title }}</span>
				<span v-else style="font-size: 0.36rem;">{{ title }}</span>
			</div>
			<div class="right" @click="onclickRight">
				<slot name="text"></slot>
				<slot name="right"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
			name: 'commonHeader',
		    props: {
		        title: {
		            type: String,
		            default: ''
		        },
		        url: {
		            type: String,
		            default: '/'
		        },
		        isReturn: {
		            type: Boolean,
		            default: true
		        },
				bg_src: {
					type: String,
					default: ''
				},
				bg_size: {
					type: String,
					default: '100% 100%'
				},
				paddingB: {
					type: String,
					default: ''
				},
	            query: {
	                type: Object,
	                default: () => {}
	            }
		    },
		    methods: {
		        onClickLeft() {
		            if (this.url && this.url !== '/') {
		                this.$router.push({
		                    path: this.url,
		                    query: this.query
		                });
		            } else {
		                this.$router.back(-1);
		            }
		        },
				onclickRight() {
					this.$emit('onclickRight')
				},
		    }
		}
	</script>
	
	<style lang="less">
		.commonHeader {
			padding: 0 .3rem;
			// padding-bottom: 6.88rem;
			// background-image: url(../assets/imgs/new_color/yaoqing_bg.png);
			background-repeat: no-repeat;
			background-position: center top;
			// background-size: 100% 6.88rem;
			z-index: 99;
			// height: .88rem;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			.head {
				position: relative;
				height: .88rem;
				line-height: .88rem;
			}
			.left {
				position: absolute;
				left: 0;
				top: 0.01rem;
				.icon {
					width: .6rem;
					height: .88rem;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				img {
					width: 0.19rem;
					height: 0.33rem;
				}
			}
			.title {
				font-family: PingFang SC;
				color: #FFFFFF;
				font-size: 0.32rem;
				font-weight: 600;
				text-align: center;
				text-transform: capitalize;
				span {
					font-weight: 600;
				}
			}
			.right {
				position: absolute;
				right: 0;
				top: 0;
				height: .88rem;
				display: flex;
				align-items: center;
				.text {
					color: #FFFFFF;
					font-size: .28rem;
				}
				img {
					width: .4rem;
					height: .4rem;
				}
			}
		}
	</style>
